import { ReactNode } from 'react';

export class Notifier {
    static set notifier(value: any) {
        this._notifier = value;
    }

    private static _notifier: any;

    static init(notifier: any) {
        Notifier._notifier = notifier;
    }

    /** @deprecated usar método success success({title?, message}) */
    static notifySuccess(message: ReactNode = '', title: ReactNode = '', options?: any) {
        Notifier._notifier.notifySuccess(message, title, options);
    }

    /** @deprecated usar método error */
    static notifyError(
        message: ReactNode = '',
        title: ReactNode = '',
        mostrarMensajeFinal = true,
        link?: any,
        options?: any,
    ) {
        Notifier._notifier.notifyError(message, title, mostrarMensajeFinal, link, options);
    }

    static success({
        title,
        message,
        link,
        options,
    }: {
        title?: string;
        message?: ReactNode;
        link?: { label: string; href: string; shouldOpenInNewTab?: boolean } | null;
        options?: any;
    }) {
        Notifier._notifier.notifySuccess(message, title, options, {
            ...link,
            target: link?.shouldOpenInNewTab ? '_blank' : '_self',
        });
    }

    static error(props: {
        message?: ReactNode;
        title?: string;
        link?: { label: string; href: string; shouldOpenInNewTab?: boolean } | null;
        options?: { autoDismiss?: number; toastId?: string };
    }) {
        Notifier._notifier.notifyError(
            props.message,
            props.title,
            false,
            { ...props.link, target: props?.link?.shouldOpenInNewTab ? '_target' : '_self' },
            props.options,
        );
    }

    static warning(props: {
        message: ReactNode;
        title?: string;
        link?: { label: string; href: string; shouldOpenInNewTab?: boolean } | null;
        options?: { autoDismiss?: number; toastId?: string };
    }) {
        Notifier._notifier.notifyWarning(
            props.message,
            props.title,
            false,
            { ...props.link, target: props?.link?.shouldOpenInNewTab ? '_target' : '_self' },
            props.options,
        );
    }

    static notifyFeedBack({ title, message, options }: { title?: ReactNode; message?: ReactNode; options?: any }) {
        Notifier._notifier.notifyFeedBack(message, title, options);
    }

    static handleNotification = (msg: ReactNode) => {
        Notifier._notifier.handleNotification(msg);
    };

    static notifyWarning = (msg: ReactNode, title: ReactNode) => {
        Notifier._notifier.handleNotification(msg, title);
    };

    static notifyInfo = (msg: ReactNode, title: ReactNode) => {
        Notifier._notifier.notifyFeedBack(msg, title);
    };

    static clearNotifications = () => {
        Notifier._notifier.clearNotifications();
    };
}
